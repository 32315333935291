// blog style
.blog__item {
    &.is__full {
        border: 1px solid var(--rts-gray);
        padding: 30px;
        box-shadow: 0px 30px 40px rgba(175, 175, 175, 0.16);

    }

    &__thumb {
        overflow: hidden;
        margin-bottom: 30px;

        img {
            transition: var(--transition);
            min-height: 320px;
            max-height: 100%;
            transform: scale(1);
        }

        &:hover {
            img {
                transform: scale(1.05);
            }
        }
    }

    &__meta {
        &__title {
            h5 {
                transition: var(--transition);
                margin-bottom: 15px;

                &:hover {
                    color: var(--heading-color);
                }
            }
        }

        &__cat {
            padding: 8px 15px;
            border-radius: 2px;
            background-color: var(--rts-gray);
            font-size: var(--p-xs);
            line-height: 1;
            color: var(--rts-para);
            margin-bottom: 20px;
            display: inline-block;
        }

        &__list {
            display: flex;
            gap: 30px;
            align-items: center;

            @include mq($lg) {
                gap: 15px;
            }

            span {
                display: flex;
                gap: 8px;
                align-items: center;

                a {
                    display: flex;
                    gap: 8px;
                    align-items: center;
                    color: var(--rts-para);
                }
            }
        }
    }
}

// blog small style
.blog__item {
    &.if__is__small {
        display: flex;
        gap: 30px;

        @include mq($xs) {
            flex-wrap: wrap;
            gap: 20px;
        }

        .blog__item__thumb {
            margin-bottom: 0;
            min-width: 195px;

            img {
                min-width: 195px;
                max-width: 195px;
                max-height: 168px;
                min-height: 168px;
            }
        }

        .blog__item__meta__title {
            h5 {
                font-size: 26px;
                line-height: 38px;
            }
        }
    }
}

.blog__small__list {
    display: flex;
    flex-direction: column;
    gap: 39px;
}

// blog style four
.blog__item {
    &.is__has__radius {
        padding: 30px;
        border-radius: 10px;
        border: 1px solid rgba(#65676B, $alpha: 0.3);

        .blog__item__thumb {
            border-radius: 10px;

            img {
                min-height: 250px;
                max-height: 250px;

                @include mq($lg) {
                    width: 100%;
                }
            }
        }
    }
}

// event style
.blog__item {
    &.is__full {
        &.is__event {
            border-radius: 6px;

            .blog__item__thumb img {
                border-radius: 6px;
            }
        }

    }
}

// main blog
.blog__list__item {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .load__more__link {
        margin-top: 30px;
    }
}

.single__blog {
    border: 1px solid var(--rts-border);
    padding: 30px;
    border-radius: 6px;

    &__thumb {
        overflow: hidden;
        margin-bottom: 30px;

        img {
            overflow: hidden;
            border-radius: 6px;
            width: 100%;
            transition: var(--transition);
            @include mq($sm) {
                height: 100%;
            }
            &:hover {
                transform: scale(1.05);
            }
        }
    }

    &__meta {
        .category {
            display: block;
            color: var(--rts-para);
            margin-bottom: 10px;
            font-size: 18px;
        }

        .h5 {
            margin-bottom: 10px;
            display: block;
        }

        p {
            margin-bottom: 20px;
        }
    }

    &__meta__main {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include mq($sm) {
            flex-wrap: wrap;
            gap: 30px;
        }
        .author__meta {
            display: flex;
            align-items: center;
            gap: 25px;
            @include mq($sm){
                flex-wrap: wrap;
                gap: 15px;
            }
            a {
                display: flex;
                gap: 10px;
                align-items: center;
                color: var(--rts-para);

                img {
                    border-radius: 50%;
                }
            }

            span {
                display: flex;
                align-items: center;
                gap: 10px;
            }
        }

        .readmore {
            a {
                background-color: var(--rts-gray);
                color: var(--rts-heading);
                padding: 8px 15px;
                border-radius: 6px;
                font-family: var(--jost);
                transition: var(--transition);

                &:hover {
                    background-color: var(--rts-heading);
                    color: var(--rts-white);
                }
            }
        }
    }
}

// blog sidebar style
.sitcky__sidebar{
    position: relative;
    top: 0;
    will-change: min-height;
    @include mq($md){
        position: unset;
    }
}
.blog__sidebar__section {
    background-color: var(--rts-gray);
    padding: 30px;
    border-radius: 6px;
    margin-bottom: 30px;
    &:last-child{
        margin-bottom: 0;
    }
    h6 {
        text-transform: capitalize;
    }

    .search__form {
        position: relative;
        border-radius: 6px;
        width: 100%;
        background-color: var(--rts-white);
        margin-bottom: 30px;

        input[type="text"] {
            width: 100%;
            padding: 13px 13px 13px 35px;
            position: relative;
            border: 0;
            border-radius: 6px;
        }

        i {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 15px;
        }
    }

    .search__item__list {
        margin-bottom: 30px;

        .list {
            border-bottom: 1px solid var(--rts-border);
            margin-bottom: 10px;
            padding-bottom: 10px;

            &:last-child {
                border-bottom: 0;
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }

        .checkbox.is__small {
            & label::before {
                height: 14px;
                width: 14px;
                border-radius: 2px;
                margin-right: 15px;
                transition: var(--transition);
            }

            & input:checked+label::after {
                top: 9px;
                left: 5px;
                width: 4px;
                height: 8px;
                border-color: var(--rts-white);
                transition: var(--transition);
            }

            & input:checked+label::before {
                border-color: var(--rts-heading);
                background-color: var(--rts-heading);
            }
        }
    }
}
.latest__post{
    display: flex;
    flex-direction: column;
    gap: 40px;
}
.single__post{
    display: flex;
    gap: 20px;
    align-items: center;
    &__thumb{
        img{
            min-width: 110px;
            object-fit: cover;
            border-radius: 6px;
        }
    }
    &__meta{
        display: flex;
        flex-direction: column;
        gap: 10px;
        a{
            color: var(--rts-heading);
            font-family: var(--jost);
        }
    }
}
.tag__list{}
.tags__list{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    a{
        padding: 8px 10px;
        border-radius: 6px;
        border: 1px solid var(--rts-border);
        display: inline-block;
        color: var(--rts-para);
        line-height: 1;
        font-size: 14px;
        transition: var(--transition);
        &:hover{
            background-color: var(--heading-color);
            border-color: var(--heading-color);
            color: var(--rts-white);
        }
    }
}

// blog details
.blog__details__content{
    .description{
        font-size: 18px;
        line-height: 30px;
    }
    .service-list{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 15px;
        @include mq($sm){
            grid-template-columns: repeat(2, 1fr);
        }
        @include mq($xs){
            grid-template-columns: repeat(1, 1fr);
        }
        li{
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 18px;
        }
    }
    .rts__blockquote{
        padding: 80px 60px 40px 30px;
        border-radius: 10px;
        background-color: var(--rts-gray);
        margin: 40px 0;
        .blockquote__text{
            position: relative;
            font-size: 24px;
            font-family: var(--glida);
            color: var(--rts-heading);
            line-height: 38px;
            &::after{
                position: absolute;
                content: "";
                height: 30px;
                width: 35px;
                background-image: url("../images/icon/quote.svg");
                top: -45px;
                left: 0;
                background-repeat: no-repeat;
            }
        }
    }
    .comment__list{
        display: flex;
        gap: 30px;
        flex-direction: column;
    }
    .comment__item{
        &__author{
            display: flex;
            align-items: center;
            gap: 20px;
            margin-bottom: 20px;
        }
    }
    .comment__text{
        a{
            display: flex;
            align-items: center;
            gap: 10px;
            color: var(--rts-heading);
        }
    }
    .share{
        .social__link{
            display: flex;
            align-items: center;
            gap: 20px;
            a{
                color: var(--rts-para);
                font-size: 20px;
                transition: var(--transition);
                &:hover{
                    i{
                        color: var(--heading-color);
                    }
                }
            }
        }
    }
}
.comment__form{
    padding: 30px;
    border-radius: 10px;
    background-color: var(--rts-gray);
    .input-group{
        flex-wrap: nowrap;
        gap: 30px;
        margin-bottom: 20px;
        @include mq($xs){
            flex-wrap: wrap;
            gap: 20px;
        }
    }
    .form-group{
        width: 100%;
        label{
            color: var(--rts-heading);
            font-size: 18px;
            font-family: var(--jost);
            display: block;
            margin-bottom: 15px;
        }
        input{
            padding: 11px 15px ;
            border: 0;

        }
        textarea{
            height: 100px;
            resize: none;
            border: none;
            padding: 11px 15px;
        }
    }
    .fill{
        padding: 12px 35px;
        font-family: var(--jost);
    }

}