.similar__form {
    max-width: 600px;
    min-width: 600px;
    @include mq($xs){
        min-width: 100%;
        max-width: 100%;
    }
    .btn-close{
        border: 0;
    }
    input:not([type="checkbox"]) {
        border: 1px solid var(--rts-border);
        padding: 10px 15px;
        border-radius: 4px;
        width: 100%;
    }
    .theme-btn{
        background-color: var(--rts-gray);
        span{
            color: var(--rts-heading);
        }
        &:hover{
            span{
                color: var(--rts-white);
            }
        }
    }
    .form-group{
        .theme-btn{
            padding: 10px 20px;
        }
    }

}

.form__padding {
    padding: 40px 45px;
}

.has__line {
    position: relative;

    &::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        background-color: var(--rts-border);
        bottom: 12px;
        left: 0;
        z-index: 1;
    }

    p {
        max-width: max-content;
        margin: 0 auto;
        padding: 0 12px;
        font-weight: 500;
        position: relative;
        z-index: 2;
        background-color: var(--rts-white);
    }
}

// form submit
.form-group {
    .rts__btn {
        padding: 18px 0;
        border-radius: 4px;
        font-weight: 500;
    }
}

.is__social {
    button {
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 15px 20px;
        border-radius: 4px;
        background-color: var(--rts-gray);
        width: 100%;
        font-size: 16px;
        color: var(--rts-para);
        transition: var(--transition);

        &:hover {
            background-color: var(--rts-primary);
            color: var(--rts-white);
        }
    }
}

// modal opening
.similar__modal {
    .modal-dialog {
        min-width: 600px;
        border-radius: 16px;
        border: 1px solid var(--rts-border);
        @include mq($xs){
            min-width: auto;
        }
    }
}

// contact method no border
.is__half__section {
    margin-top: -150px;
    @include mq($sm){
        margin-top: 0;
        padding-top: 100px;
    }
}

.is__no__border {
    .rts__workprocess__box {
        border: none;
    }
}

.shadow-rt-sm {
    box-shadow: 0px 4px 80px rgba(28, 28, 28, 0.1);


}

// form is dark
[data-theme="dark"] {
    .similar__form{
        h6, label, span, p{
            color: #000837 !important;
        }
        .has__line p{
            background-color: #fff;
        }
    }
}