/* ---------------------
All CSS Structure Follow this Order
----------------------

1. Variable
1. mixin
3. reset
4. typography
5. Spacing
6. loader
7. common
8. animation
9. input
10. components
11. common style
12. banner
13. about
14. rooms
15. testimonial
16. video
17. offer
18. advance__search
19. newsletter
20. facility
21. blog
22. service
23. team
24. resturant-menu
25. gallery
26. event
27. offcanvase__menu
28. contact
29. form
30. footer
-------------*/