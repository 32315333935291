.rts__circle {
    max-width: max-content;
    border: 1px solid var(--rts-white);
    border-radius: 50%;
    svg {
        height: 172px;
        width: 172px;
        position: relative;
        padding: 1px;
        animation: rotate 10s linear infinite;
        @include mq($sm){
            height: 140px;
            width: 140px;
        }
        text {
            textPath {
                font-size: 11.5px;
                fill: var(--rts-white);
                font-family: var(--jost);
            }
        }
    }

    &--icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        i {
            transform: rotate(-30deg);
            color: var(--rts-white);
        }
    }

   
}
@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

// dark mode
[data-theme="dark"]{
    .rts__circle{
        border-color: var(--rts-para);
    }
    .rts__circle svg text textPath{
        fill: var(--rts-para);
    }
    .rts__circle--icon i{
        color: var(--rts-para);
    }
}