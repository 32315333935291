
// PRELOADER CSS
.loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;

    .loader-section {
        position: fixed;
        top: 0;
        background: var(--rts-white);
        width: 50%;
        height: 100%;
        z-index: 1000;
    }
}

.loader-wrapper .loader-section.section-left {
    left: 0
}

.loader-wrapper .loader-section.section-right {
    right: 0;
}

/* Loaded Styles */
.loaded .loader-wrapper .loader-section.section-left {
    // transform: translateX(-100%);
    transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
    opacity: 0;
}

.loaded .loader-wrapper .loader-section.section-right {
    // transform: translateX(100%);
    transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
    opacity: 0;
}

.loaded .loader {
    opacity: 0;
    transition: all 0.3s ease-out;
}

.loaded .loader-wrapper {
    visibility: hidden;
    transform: translateY(-100%);
    transition: all .3s 1s ease-out;
}

.loader:after {
    content: "";
    position: absolute;
    top: 14px;
    left: 14px;
    right: 14px;
    bottom: 14px;
    border: 4px solid transparent;
    border-top-color: var(--rts-primary);
    border-right-color: var(--rts-primary);
    border-bottom-color: var(--rts-primary);
    border-radius: 100%;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
}

.loader {
    display: block;
    position: relative;
    top: 50%;
    left: 50%;
    width: 70px;
    height: 70px;
    z-index: 1001;
    transform: translate(-50%, -50%);
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}