.room__card {
    border: 1px solid var(--rts-border);
    border-radius: 10px;
    overflow: hidden;

    &__top {
        position: relative;
        overflow: hidden;

        .room__card__image {
            overflow: hidden;
            height: 100%;

            img {
                transition: var(--transition);
                width: 100%;

                &:hover {
                    transform: scale(1.05);
                }
            }
        }

        .room__price__tag {
            position: absolute;
            top: 30px;
            left: 30px;
            background-color: var(--rts-white);
            border-radius: 6px;

            span {
                margin-bottom: 0;
                display: block;
                padding: 10px;
                line-height: 1;
                color: var(--heading-color);
            }
        }
    }

    &__meta {
        padding: 30px;

        .room__card__title {
            display: block;
            margin-bottom: 15px;
            transition: var(--transition);

            &:hover {
                color: var(--heading-color);
            }
        }

        .room__price__tag {
            span {
                color: var(--heading-color);
            }
        }

        &__info {
            display: flex;
            gap: 15px;
            margin-bottom: 10px;

            @include mq($xs) {
                flex-wrap: wrap;
                gap: 10px;
            }

            span {
                display: flex;
                gap: 5px;
                align-items: center;
            }
        }

        .room__card__link {
            font-size: 18px;
            color: var(--heading-color);
            text-decoration: underline;
            font-family: var(--jost);
        }
    }

    &.is__style__three {
        .room__card__meta__info {
            span {
                font-size: 24px;

                @include mq($lg) {
                    font-size: 18px;
                }
            }
        }
    }

    &.is__style__four {
        border-color: transparent;
        display: flex;
        flex-direction: row;
        align-items: center;

        @include mq($sm) {
            flex-direction: column;
        }

        .room__card__top {
            flex-basis: 50%;

            .room__card__image {
                border-radius: 10px;

                a {
                    display: block;
                }

                img {
                    border-radius: 10px;
                }
            }
        }

        .room__card__meta {
            flex-basis: 50%;
            margin-left: 30px;

            @include mq($sm) {
                margin-left: 0;
                padding-left: 0;
                flex-basis: unset;
                padding-bottom: 0;
                padding-right: 0;
            }

            &__info {
                font-size: 20px;

                @include mq($md) {
                    flex-wrap: wrap;
                }
            }
        }
    }
}

.load__more__link {
    margin-top: 40px;
    display: flex;
    justify-content: center;

    a {
        border: 1px solid var(--rts-border);
        padding: 8px 15px;
        display: inline-block;
        border-radius: 6px;
        font-size: 18px;
        font-family: var(--jost);
        color: var(--rts-heading);
        transition: var(--transition);

        &:hover {
            background-color: var(--heading-color);
            color: var(--rts-white);
        }
    }
}

// room style four
.room__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 60px;

    @include mq($sm) {
        row-gap: 0;
    }

    .room__card {
        &.is__style__four {
            &:nth-child(even) {
                flex-direction: row-reverse;

                @include mq($sm) {
                    flex-direction: column;
                }

                .room__card__meta {
                    padding: 0;
                    margin-left: 0;
                    margin-right: 30px;
                    @include mq($sm){
                        margin-right: 0;
                        padding-top: 30px;
                    }
                }
            }
        }
    }
}