/*
Template Name: Moonlit - Hotel and Resturant HTML Template
Author: Reacthemes
Author URI: https://themeforest.net/user/reacthemes
Tags: Hotel, Resturant, Spa, Message, Honeymoon, Booking
Version: 1.0
*/

@import 'default/serialize';
@import 'default/variables';
@import 'mixin/mixin';
@import 'default/reset';
@import 'default/typography';
@import 'default/spacing';
@import 'default/loader';
@import 'default/animation';
@import 'default/common';
@import 'default/animation';
@import 'default/input';
@import 'components/components';

@import 'section/header__top';
@import 'section/header';
@import 'section/section';

/* gdpr cookies */
[data-theme="dark"] {
    .gdprcookie {
        background: #1b1b1b;
    }
    .gdprcookie h2 {
        color: #fff;
    }
}
.gdprcookie {
    position: fixed;
    color: white;
    font-size: 0.8em;
    line-height: 1.5em;
    left: 1.5rem;
    bottom: 1.5rem;
    max-width: 34em;
    padding: 30px 20px;
    background: var(--rts-heading);
    z-index: 99999;
}

.gdprcookie h2 {
    color: var(--rts-white);
    font-size: 20px;
    line-height: 1.2;
    margin-bottom: 20px;
}

.gdprcookie-intro\/ p {
    margin-bottom: 20px;
    line-height: 26px;
}

.gdprcookie-buttons\/ button {
    display: inline-block;
    border: 0;
    margin-right: 12px;
    padding: 10px 15px;
    font-family: var(--jost);
    line-height: 1;
}

.gdprcookie-types\/ {
    h3 {
        color: #fff;
        font-size: 18px;
        line-height: 1.3;
        margin-bottom: 10;
    }

    ul {
        padding: 0;
        list-style: none;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 5px;
        margin-bottom: 20px;
        li {
            display: flex;
            gap: 5px;
        }
    }

}

/*
if you need custom styles, please add it in this file below

For example:
.custom{
    color: red;
}
*/



/*
If you love our template, please give us a star on https://themeforest.net/user/reacthemes
*/